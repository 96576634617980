
<template>
    <div>
      <div class="contentContainer">

        
        <div class="loginBox">

        <img class="imgLogo" :src="require(`@/assets/logo_trans.png`)" />


        <div v-if="authStore.errorMessage"  class="dangerAlert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>

          <form @submit.prevent="authStore.login">

            <div class="inputGroup">
              <label for="username">Email</label>
              <input type="text" id="username" v-model="authStore.loginFormData.email" required />
            </div>

            <div class="inputGroup">
              <label for="password">Password</label>
              <input type="password" id="password"  v-model="authStore.loginFormData.password" required />
            </div>

            <button class="theSubBtn" type="submit">Login</button>

          </form>
        </div>
      </div>
    </div>
  </template>


<script setup>
import { useAuthStore } from '@/stores/AuthStore';

const authStore = useAuthStore();



</script>

<style scoped>
/* Container to center the login box */
.contentContainer {
  position: relative;         /* Needed for the pseudo-element positioning */
  display: flex;
  justify-content: center;    /* Centers horizontally */
  align-items: center;        /* Centers vertically */
  min-height: 100vh;          /* Ensures the container takes full viewport height */
  background-image: url('http://weddory.com/wp-content/uploads/2024/09/weddory-wedding-blog.webp');
  background-size: cover;     /* Scales the background image to cover the container */
  background-position: center;/* Centers the background image */
  background-repeat: no-repeat;/* Prevents the image from repeating */
}

/* Pseudo-element for the black overlay */
.contentContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1; /* Places the overlay below the login box */
}

/* Login box styling */
.loginBox {
  position: relative;  /* Needed to position above the overlay */
  z-index: 2;          /* Places the login box above the overlay */
  width: 350px;
  padding: 40px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Input group styling */
.inputGroup {
  margin-bottom: 15px;
  text-align: left;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none; /* Removed border for cleaner look */
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent input background */
}

/* Submit button styling */
button[type='submit'] {
  width: 100%;
  padding: 10px;
  background-color: #000000; /* Bootstrap primary color */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

button[type='submit']:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Responsive adjustments */
@media (max-width: 400px) {
  .loginBox {
    width: 90%;
    padding: 20px;
  }
}

/* Logo styling */
.imgLogo {
  width: 110px;
  margin-bottom: 35px;
}

.theSubBtn{}



</style>
