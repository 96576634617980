<template>
    <div class="theSideBar">



        <div class="logoWrapper">

            <img class="imgLogo" :src="require(`@/assets/logo_trans.png`)" />

        </div>



        <div class="mainMenuWrapper">



            <div class="linkStyle">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <i class="bi bi-gem"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Rings</span>

                    </div>

                </div>

            </div>


            <div class="linkStyle">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <img width="20" :src="require(`@/assets/gown.png`)" />

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Dresses</span>

                    </div>

                </div>

            </div>


            <div class="linkStyle">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <img width="20" :src="require(`@/assets/cake.png`)" />

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Cakes</span>

                    </div>

                </div>

            </div>



            <div class="linkStyle">

                <div class="menuItemRow">


                    <div class="iconArea">

                        <i class="bi bi-gear"></i>

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 0px;">Account</span>

                    </div>

                </div>

            </div>



            <div class="menuItemRow">


                <div class="iconArea">

                    <i class="bi bi-box-arrow-right"></i>

                </div>

                <div class="labelArea">

                    <span style="float: left; margin-left: 0px;">Logout</span>

                </div>

            </div>





        </div>





    </div>
</template>

<script setup>





</script>

<style scoped>
.theSideBar {
    /* background-color: #f9f9f9; */
    width: 17%;
    overflow-y: auto;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.logoWrapper {
    display: flex;
    /* Enable Flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    width: 100%;
    height: 50px;
    padding-top: 20px;

    /* background-color: aqua; */
}

.imgLogo {
    width: 110px;
}


.mainMenuWrapper {
    /* background-color: aqua; */
    width: 100%;
    margin-top: 20px;
    /* padding: 20px; */
}

.menuItemRow {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;

}


.iconArea {
    /* background-color: #b8ffd3; */
    width: 10%;
    display: flex;
    align-items: center;
}

.labelArea {

    /* background-color: #36e1e1; */
    width: 80%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}


.menuItemRow:hover {
    background-color: rgb(232, 243, 234);
}

.menuItemRowSelected {
    background-color: rgb(232, 243, 234);
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 20px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}
</style>