/* eslint-disable */
import WeddingService from "@/services/WeddingService";
import { defineStore } from "pinia";




export const useWeddingStore = defineStore("WeddingStore", {

  state: () => ({


 
    imageUrl: null,

    ringFormData: {
      prompt: null,
    },

    dressFormData: {
      prompt: null,
    },


    isLoading: false,

    showRingResultModal: false,
    showDressResultModal: false,

    ringUrl: null,
    dressUrl: null,
    

    userRings: [],
    userDresses: [],

    showPreviewImageModal: false,

    currentImage: null,
    

    showCreditLimitReachedModal: false,



  }),



  actions: {



    fetchAllRings(){ 

      WeddingService.getAllRings()
        .then((response) => {

          this.userRings = response.data.rings

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },


    fetchAllDresses(){ 

      WeddingService.getAllDresses()
        .then((response) => {

          this.userDresses = response.data.dresses

          console.log(response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });

    },
  
    generateRingThisImage(){


      this.isLoading = true

      const formData = {
        allData: {
          prompt: this.ringFormData.prompt
        }
      }


      WeddingService.generateRingImage(formData)
        .then((response) => {


          let message = response.data.message;

          if(message === 'limit_reached'){

            this.isLoading = false

            this.showCreditLimitReachedModal = true

            

          }

          if(message === 'proceed'){

             this.ringUrl = response.data.imageUrl

              this.showRingResultModal = true

              console.log(response.data)

              this.isLoading = false

              this.fetchAllRings()


          }


         

          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });


      console.log(formData)

    },


    generateDressImage(){


      this.isLoading = true

      const formData = {
        allData: {
          prompt: this.dressFormData.prompt
        }
      }


      WeddingService.generateDressImage(formData)
        .then((response) => {


          let message = response.data.message;

          if(message === 'limit_reached'){

            this.isLoading = false

            this.showCreditLimitReachedModal = true

            

          }

          if(message === 'proceed'){


              this.dressUrl = response.data.imageUrl

              this.showDressResultModal = true

              console.log(response.data)

              this.isLoading = false

              this.fetchAllDresses()
           

          }

         

          
        }).catch((err) => {

          this.isLoading = false

          console.log(err)
          
        });


      console.log(formData)

    },

    openRingResultModal(){
      this.showRingResultModal = true
    },

    closeRingResultModal(){
      this.showRingResultModal = false 
     
    },


    openDressResultModal(){
      this.showDressResultModal = true
    },

    closeDressResultModal(){
      this.showDressResultModal = false
    },

    downloadRingFile() {

      let downloadURL = this.ringUrl;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = 'ring_design'; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },

    downloadDressFile() {

      let downloadURL = this.dressUrl;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = 'dress_design'; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },

    downloadAnyImageFile() {

      let downloadURL = this.currentImage.do_url;

      fetch(downloadURL)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = 'weddory_design'; // Assuming theFile has a name property
          link.click();
        })
        .catch(console.error);


    },

    tryRingAgain() {
      this.showRingResultModal = false
    },

    setCurrentImage(image){

      this.currentImage = image
      this.openPreviewImageModal()

    },

    openPreviewImageModal(){
      this.showPreviewImageModal = true
    },


    closePreviewImageModal(){
      this.showPreviewImageModal = false
    },


    openCreditLimitReachedModal(){
      this.showCreditLimitReachedModal = true
    },

    closeCreditLimitReachedModal(){
      this.showCreditLimitReachedModal = false
    }




    
     





  },


});
