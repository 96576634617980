<template>
    <div class="bottomNavBar">




        <router-link class="navItem" :class="{ navItemSelected: isDressScreenActive }"
            :to="{ name: 'DressScreen' }">

            <img width="20" :src="require(`@/assets/gown.png`)" />
            Dresses

        </router-link>

   

        <router-link class="navItem" :class="{ navItemSelected: isRingScreenActive }"
        :to="{ name: 'RingScreen' }">

            <i class="bi bi-gem"></i>
            Rings


        </router-link>




        <div class="navItem" @click="cakeMessage"
            >


            <img width="20" :src="require(`@/assets/cake.png`)" />
            Cakes


        </div>


   


        <router-link class="navItem" :class="{ navItemSelected: isMyAccountScreenActive }"
        :to="{ name: 'MyAccount' }">

            <i class="bi bi-people"></i>
                Account


        </router-link>

    





    </div>
</template>

<script setup> 

import { useRoute } from 'vue-router';
import { computed } from "vue";


const route = useRoute();

const isDressScreenActive = computed(() => route.name === 'DressScreen'); 
const isRingScreenActive = computed(() => route.name === 'RingScreen'); 
const isMyAccountScreenActive = computed(() => route.name === 'MyAccount'); 


const cakeMessage = () => {
    alert('Cake image generation feature is coming soon!')
}




</script>

<style scoped>
.bottomNavBar {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    display: flex;
    justify-content: space-around;
    /* This will space out the nav items evenly */
    align-items: center;
    /* This will vertically center the items */
    position: fixed;
    /* Fix the nav bar to the bottom */
    bottom: 0;
    /* Align to the bottom */
    left: 0;
    width: 100%;
    /* Full width */
    background-color: white;
    /* Example background color, change as needed */
    color: white;
    /* Example text color, change as needed */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
}

.navItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    /* background-color: #e3ebe6; */
    text-align: center;
    /* Center the text inside each nav item */
    flex-grow: 1;
    /* Each nav item takes up equal space */
    color: black;
    margin: 4px;
    font-size: 12px;
    border-radius: 10px;
    text-decoration: none;
}

.navItem:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #e3ebe6;
    text-align: center;
    /* Center the text inside each nav item */
    flex-grow: 1;
    /* Each nav item takes up equal space */
    color: black;
    margin: 4px;
    font-size: 13px;
    font-weight: bolder;
    border-radius: 10px;
    transition: 1s;
}

.navItemSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #e3ebe6;

    text-align: center;
    /* Center the text inside each nav item */
    flex-grow: 1;
    /* Each nav item takes up equal space */
    color: black;
    margin: 4px;
    font-size: 13px;
    font-weight: bolder;
    border-radius: 10px;
}


.linkStyle {
    text-decoration: none;
    color: inherit;
}
</style>
