/* eslint-disable */
import AuthService from "@/services/AuthService";
import { defineStore } from "pinia";
import apiClient from "@/services/MyClient";



export const useAuthStore = defineStore("authStore", {

  state: () => ({


 
    registerFormData: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      device_name: "web",
    },
    

    loginFormData: {
      email: "",
      password: "",
    },

    errorMessage: '',
    successMessage: "",
    errorArray: [],

    token: null,

    user: {
      name: "", //username
      rid: null, //role id
    },


    showMenuModal: false,


    userInfo: null,


  }),


  persist: {
    pick: ['user.name', 'token'],
  },





  actions: {



    registerUser() { 


      this.errorMessage = "";
      this.errorArray = [];

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.registerUser(this.registerFormData)
            .then((response) => {

              console.log(response.data);

              this.router.push({ name: "LoginView" });

            })
            .catch((err) => {
        
              

              if (
                err.response &&
                err.response.data &&
                err.response.data.errors
              ) {
                let theErrors = err.response.data.errors;

                Object.values(theErrors).forEach((errorArray) => {
                  errorArray.forEach((error) => {
                    this.errorArray.push(error);
                  });
                });
                console.log(theErrors);
              } else {
                this.isLoading = false;
                // Handle the case where the error format is not as expected
                console.log("An unexpected error occurred:", err);
              }
            });
            
            
        })
        .catch((err) => {

          
          console.log(err.data);
        });



    },


    async login() { 

      this.errorMessage = "";

      apiClient
        .get("/sanctum/csrf-cookie")
        .then(() => {
          AuthService.loginUser(this.loginFormData)
            .then((response) => {
           
              
              //set token
              this.token = response.data.token;

              //store some user info
              this.user.name = response.data.user.name;
              this.user.rid = response.data.user.role_id;

              //important: add upate the header with token
              apiClient.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${this.token}`;
                return config;
              });


              this.router.push({ name: "DressScreen" });

              console.log(response.data);
            })
            .catch((err) => {
              this.isLoading = false;

              // this.errorMessage = err.response.data.error
              this.errorMessage =
                "Unauthorised. Please make sure your email and password are correct.";

              console.log(err);
            });
        })
        .catch((error) => {
          console.log(error);
        });


    },

    async logoutUser() { 

      AuthService.logout()
        .then((response) => {

          //remove token from localstorage
          this.token = "";

          //clear login data from
          this.user.name = "";
          this.user.rid = null;

          window.localStorage.clear();

          this.router.push({ name: "LoginView" });

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },


    async fetchUserInfo() {  

      AuthService.getUserDetails()
        .then((response) => {


          this.userInfo = response.data.user

          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },


    openMenuModal(){
      this.showMenuModal = true
    },

    closeMenuModal(){
      this.showMenuModal = false
    },

  

    

    
     





  },


});
