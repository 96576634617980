<template>
    <div class="mainWrapper">


        <MainSideBar v-if="windowWidth > mobileWidthThreshold" />

        <div v-else>

            <MobileTopNav />

            <MobileBottomNav />

        </div>







        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 40px;' : ''">


            <div class="topNav">
                <TopBar v-if="windowWidth > mobileWidthThreshold" />
            </div>


            <div class="innerContainer">

                <!-- <button @click="weddingStore.openDressResultModal">open</button> -->
                <!-- <button @click="weddingStore.openPreviewImageModal">open</button> -->



                <div class="theTitle">
                    <h5>Describe Your Dream Wedding Dress</h5>
                </div>


                <div class="theFormBox">


                    <form @submit.prevent="weddingStore.generateDressImage" class="theFormWrapper">


                        <div class="mb-3">

                            <textarea v-model="weddingStore.dressFormData.prompt" class="form-control theInput" rows="4"
                                cols="50" style="resize: none; height: 200px;"
                                placeholder="Create a design for an A-line wedding dress with a fitted bodice and flowing skirt. Incorporate delicate lace details along the neckline and a soft, satin fabric that adds an elegant drape, perfect for a romantic outdoor wedding."
                                required></textarea>

                        </div>

                        <div v-if="weddingStore.isLoading" class="theGIFBox">

                            <div class="innerbox">

                                <div>
                                    <b style="font-size: 14px;">Generating your dress...</b>
                                </div>

                                <img class="theCakeLoadingImg" :src="require(`@/assets/dress2.gif`)" />

                            </div>



                        </div>

                        <button v-else type="submit" class="btn theSubmitBtn"><b>Generate</b></button>

                    </form>




                </div>




                <div class="theImgList" v-if="weddingStore.userDresses.length > 0">


                    <div class="ringHead">

                        Your Dresses

                    </div>


                    <div class="theImgWrapper">

                        <div class="imgItem" v-for="(dress, index) in weddingStore.userDresses" :key="index">


                            <img @click="weddingStore.setCurrentImage(dress)" :src="dress.do_url">

                        </div>



                    </div>



                </div>







            </div>






        </div>


        <div v-if="weddingStore.showDressResultModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px; height: 650px; overflow-y: auto;">

                <div class="model-box-header">

                    <div class="theButtonBox">

                        <button @click="weddingStore.downloadDressFile" class="btn btn-dark" style="font-size: 13px;">
                            <b>Download</b>
                        </button>


                        <div>
                            <i @click="weddingStore.closeDressResultModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer"></i>
                        </div>




                    </div>

                </div>

                <div class="modal-content">



                    <div class="resultsBox">




                        <img class="theRingImg" :src="weddingStore.dressUrl">

                        <!-- <img class="theRingImg"
                            src="https://res.cloudinary.com/dcv2wpei6/image/upload/v1726604475/u/7/wedding_dress_image_1726604474.jpg"> -->




                    </div>


                </div>
            </div>
        </div>


        <div v-if="weddingStore.showPreviewImageModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">

                    <div class="theButtonBox">

                        <button @click="weddingStore.downloadAnyImageFile" class="btn btn-dark"
                            style="font-size: 13px;">
                            <b>Download</b>
                        </button>


                        <div>
                            <i @click="weddingStore.closePreviewImageModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer"></i>
                        </div>




                    </div>

                </div>

                <div class="modal-content">



                    <div class="resultsBox">




                        <img class="theRingImg" :src="weddingStore.currentImage.do_url">






                    </div>


                </div>
            </div>
        </div>

        <div v-if="weddingStore.showCreditLimitReachedModal" class="modal-overlay">

            <div class="modal-box" style="width: 550px">

                <div class="model-box-header">

                    <div class="theButtonBox">

                        <div>
                            <h5>Oops you ran out of credits</h5>
                        </div>


                        <div>
                            <i @click="weddingStore.closeCreditLimitReachedModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer"></i>
                        </div>




                    </div>

                </div>

                <div class="modal-content">



                    <div>
                       Don't worry yout can topup with more crdits
                    </div>

                    <div>

                        <router-link class="btn btn-dark" style="width: 100%; margin-top: 20px;"
                        :to="{ name: 'MyAccount' }">

                        <b>Buy 35 Credits Topup ($20)</b>
                        
                        </router-link>

                    </div>
                   
                    


                </div>
            </div>
        </div>





    </div>
</template>

<script setup>

import { ref, onMounted, onUnmounted } from 'vue';
// import MainNavBar from '@/components/MainNavBar.vue';
import MainSideBar from '@/components/MainSideBar.vue';
import MobileBottomNav from '@/components/MobileBottomNav.vue';
import MobileTopNav from '@/components/MobileTopNav.vue';
import TopBar from "@/components/TopBar.vue";
import { useWeddingStore } from '@/stores/WeddingStore';


const weddingStore = useWeddingStore();



const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed


const onResize = () => {
    windowWidth.value = window.innerWidth;
};








onMounted(() => {

    window.addEventListener('resize', onResize);

    weddingStore.fetchAllDresses()


});

onUnmounted(() => {
    window.removeEventListener('resize', onResize);
});



</script>

<style scoped>
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: rgb(218, 210, 226); */
    overflow-y: auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;

}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    color: initial;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}

.theTitle {
    width: 100%;
    margin-bottom: 20px;

    /* background-color: gray; */
}

.theFormBox {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: yellow; */
}

.genCountBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    /* background-color: yellow; */
}

.theInput {
    border: 1.5px solid rgb(103, 103, 103);
}

.theSubmitBtn {
    width: 100%;
    background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
    color: white;
    border: none;
    padding: 18px;
}


.theImgList {
    width: 100%;
    /* background-color: antiquewhite; */
    margin-top: 60px;
}

.ringHead {
    text-align: left;
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 20px;
}

.theImgWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    /* background-color: aquamarine; */
}

.imgItem {
    /* background-color: red; */
    border-radius: 10px;
    flex: 0 0 calc(16.66% - 10px);
    /* 6 items per row */
    margin: 5px;
    overflow: hidden;
    /* Hide overflow */
    position: relative;
    /* Ensure positioning context */
    aspect-ratio: 1 / 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.imgItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Scale image to cover container */
    object-position: center;
    /* Center the image */
    display: block;
    /* Remove default inline spacing */
}

/* .imgItem {
    background-color: red;
    border-radius: 10px;
    height: 200px;
    flex: 0 0 calc(16.66% - 10px); 
    margin: 5px;
} */

@media (max-width: 1230px) {
    .imgItem {
        flex: 0 0 calc(50% - 10px);
        /* 2 items per row */
    }
}

.theFormWrapper {
    width: 500px;
}


.theGIFBox {
    display: flex;
    width: 100%;
    justify-content: center;
    /* background-color: yellow; */
}

.theCakeLoadingImg {
    width: 100px;
}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.buttonOptionsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    margin-top: 15px;
    padding: 20px;
}

.theButtonBox {
    display: flex;
    justify-content: space-between;
    /* background-color: purple; */
    width: 100%;
}

.resultsBox {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;


    /* background-color: rgb(137, 158, 177); */
}

.theRingImg {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
</style>