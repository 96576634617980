import apiClient from "./MyClient";


export default { 
  

 

  generateRingImage(formData){


    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/generate/image/ring", formData);

  },


  getAllRings(){


    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/all/user/rings"); 

  },



  generateDressImage(formData){


    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.post("/api/generate/image/dress", formData);

  },


  getAllDresses(){


    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/all/user/dresses"); 

  },



 
 

};
