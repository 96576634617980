import { createRouter, createWebHistory } from 'vue-router'


import RingScreen from '@/views/RingScreen.vue'
import LoginView from '@/views/Auth/LoginView.vue'
import RegisterView from '@/views/Auth/RegisterView.vue'
import { useAuthStore } from '@/stores/AuthStore'
import DressScreen from '@/views/DressScreen.vue'
import MyAccount from '@/views/Auth/MyAccount.vue'







const routes = [


  {
    path: '/',
    name: 'RingScreen',
    component: RingScreen,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore();

      if (authStore.user.rid == 1) {
        
        return next(); // Proceed to the route
      }
      return authStore.token ? next() : next('/auth/login');
    },
  },

  {
    path: '/dresses',
    name: 'DressScreen',
    component: DressScreen,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore();

      if (authStore.user.rid == 1) {
        
        return next(); // Proceed to the route
      }
      return authStore.token ? next() : next('/auth/login');
    },
  },

  {
    path: '/account',
    name: 'MyAccount',
    component: MyAccount,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore();

      if (authStore.user.rid == 1) {
        
        return next(); 
      }
      return authStore.token ? next() : next('/auth/login');
    },
  },


  {
    path: '/auth/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },



  {
    path: '/auth/register',
    name: 'RegisterView',
    component: RegisterView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/') : next()
    },
  },


 
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
